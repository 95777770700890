import React, { Link } from 'react-router-dom'
import telephone from 'assets/icons/telephone.svg'
import email from 'assets/icons/email.svg'
import whatsapp from 'assets/icons/whatsapp.svg'

function Bottom() {
  return (
    <div className='fixed bottom-0 z-30 flex w-full justify-between gap-4 bg-[#006f45] p-4 text-[10px] text-white md:justify-around md:text-xl'>
      <Link to='simulacao' className='hidden cursor-pointer md:block'>
        Faça uma Simulação:
      </Link>
      <a
        href='https://api.whatsapp.com/send/?phone=554436498400&text&type=phone_number&app_absent=0'
        target='_blank'
        className='flex items-center justify-center gap-1'
      >
        <img src={telephone} alt='Icone do telefone' className='h-6 w-6' />
        <span>(44) 3649-8400</span>
      </a>
      <a href='mailto:orcamento@ictus.solar' target='_blank' className='flex items-center justify-center gap-1'>
        <img src={email} alt='Icone do email' className='h-6 w-6' />
        <span>orcamento@ictus.solar</span>
      </a>
      <a
        href='https://api.whatsapp.com/send/?phone=554436498400&text&type=phone_number&app_absent=0'
        target='_blank'
        className='flex items-center justify-center gap-1'
      >
        <img src={whatsapp} alt='Icone do whatsapp' className='h-6 w-6' />
        <span>Whatsapp</span>
      </a>
    </div>
  )
}

export default Bottom
