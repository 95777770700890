import React from 'react'
import background from 'assets/images/saibaMais.webp'
import mapa from 'assets/images/mapa.webp'

function SaibaMais() {
  return (
    <section
      className='flex w-full items-center justify-center bg-cover bg-center bg-no-repeat'
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className='my-10 flex w-11/12 flex-col-reverse items-center justify-center gap-8 text-white md:my-20 md:w-10/12 md:flex-row md:gap-12'>
        <picture className='flex flex-1 justify-center gap-4'>
          <img src={mapa} alt='Estados Atendidos' loading='lazy' />
        </picture>

        <div className='flex flex-1 flex-col justify-center gap-4'>
          <header className='text-3xl font-semibold md:text-5xl'>ICTUS: Energizando 45 Municípios em 5 Estados</header>

          <p className='text-justify md:text-2xl'>
            A ICTUS atua em 45 municípios de 5 estados, com mais de 8500 módulos solares instalados. Destacamos nossos
            projetos em Toledo-PR, Palotina-PR, Mercedes-PR, Londrina-PR e no Senado Federal. Oferecemos soluções
            completas em energia solar.
          </p>

          <a
            href='https://api.whatsapp.com/send/?phone=554436498400&text&type=phone_number&app_absent=0'
            target='_blank'
            className='w-full rounded-xl bg-[#50af8a] p-4 text-center text-2xl uppercase text-white hover:opacity-75 md:w-2/3 md:text-3xl'
          >
            Torne-se Padrão Ictus
          </a>
        </div>
      </div>
    </section>
  )
}

export default SaibaMais
