import React, { useState, useEffect, useRef } from 'react';
import popup from 'assets/images/popup.png';

const Popup = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(true); // Alterado para true para testar
  const popupRef = useRef(null);

  useEffect(() => {
    const handleScroll = (event) => {
      if (isVisible) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    const handleClickOutside = (event) => {
      if (isVisible && popupRef.current && !popupRef.current.contains(event.target)) {
        handlePopupClose();
      }
    };

    if (isVisible) {
      document.body.style.overflow = 'hidden';
      window.addEventListener('scroll', handleScroll, { passive: false });
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'auto'; // Certifique-se de que o estilo seja restaurado ao desmontar
    };
  }, [isVisible]);

  const handlePopupClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <main
      className={`z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-65 backdrop-blur-md transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      onClick={handlePopupClose}
    >
      <article
        className="relative flex flex-col justify-center items-center p-8 rounded-lg"
        onClick={(e) => e.stopPropagation()}
        ref={popupRef}
      >
        <a href='https://forms.ploomes.com/form/bf938f7a2c36429c97a525379688d6f4' target='_blank'>
          <img
            src={popup}
            alt="Popup"
            loading='lazy'
            className="mb-8 rounded-xl max-w-full max-h-screen md:max-w-xl cursor-pointer"
          />
        </a>
        <a
          href='https://forms.ploomes.com/form/bf938f7a2c36429c97a525379688d6f4' target='_blank'
          className="bg-[#FFE500] text-lg md:text-xl text-black p-4 rounded-xl text-center font-bold uppercase hover:opacity-75 animate-bounce"
        >
          Garanta sua vaga
        </a>
      </article>
    </main>
  );
};

export default Popup;